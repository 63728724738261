<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="用户名" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="username">
            <a-input v-model="form.username" placeholder="请输入用户名..." />
          </a-form-model-item>
        </a-col>


        <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
          <a-form-model-item style="text-align:right;">

            <a-button type="primary" @click="search">
              搜索
            </a-button>
            <a-button @click="reset('ruleForm')">重置</a-button>

          </a-form-model-item>
        </a-col>
      </a-form-model>
    </div>
    <div class="mainContent" style="display: flex;justify-content: flex-end;">
      <a-button type="primary" @click="onAddUser">
        添加用户
      </a-button>
    </div>
    <div class="mainContent">

      <div class="tableHeader">
        <div class="title">查询表格</div>
        <div class="operate">

          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start"
              :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table :size="tableSize" :scroll="{ x: true }"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns"
        :row-key="record => record.id" :data-source="data" :pagination="pagination" :loading="loading" bordered
        @change="handleTableChange">


        <!-- 操作单元格 -->
        <template slot="操作" slot-scope="data">

          <span>

            <a-button size="small" type="primary" @click="onChangePassword(data)">修改密码</a-button>
            <a-popconfirm title="是否删除此账号?" @confirm="() => onDisableUser(data)">
              <a-button size="small" type="danger">删除</a-button type="primary">
            </a-popconfirm>

          </span>

        </template>
      </a-table>
    </div>


    <!-- 修改密码模态框 -->
    <a-modal title="修改密码" :visible="changePasswordModalVisible" :confirm-loading="changePasswordConfirmLoading"
      @ok="savePassword" @cancel="cancelChangePassword">

      <a-form-model ref="changePassword" :model="changePasswordForm" :rules="changePasswordFormRules"
        :label-col="{span:7}" :wrapper-col="{span:17}">
        <a-form-model-item label="新密码" prop="password">
          <a-input v-model="changePasswordForm.password" />
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="password1">
          <a-input v-model="changePasswordForm.password1" />
        </a-form-model-item>

      </a-form-model>
    </a-modal>
    <!-- 添加帐号信息模态框 -->
    <a-modal title="添加" :visible="addModalVisible" :confirm-loading="addConfirmLoading" @ok="add_saveUser"
      @cancel="add_cancelUser">
      <!-- 添加 -->
      <a-form-model ref="addUser" :model="addUserForm" :rules="addUserFormRules" :label-col="{span:7}"
        :wrapper-col="{span:17}">

        <a-form-model-item label="用户名" prop="username">
          <a-input v-model="addUserForm.username" />
        </a-form-model-item>
        <a-form-model-item label="密码" prop="password">
          <a-input v-model="addUserForm.password" />
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="password1">
          <a-input v-model="addUserForm.password1" />
        </a-form-model-item>

      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
  import { getUserList, editUser, addUser, deleteUser, updateMerchants } from "@/axios/user.js"
  import { myMixin } from "@/static/js/mixin.js"
  import moment from 'moment';
  const columns = [//列描述
    {
      align: 'center',
      title: 'ID',
      dataIndex: 'id',
    },
    {
      align: 'center',
      title: '用户名',
      dataIndex: 'username',
    },



    {
      align: 'center',
      title: '操作',
      // dataIndex: 'status',
      scopedSlots: { customRender: '操作', },
    },

  ];
  export default {
    filters: {


    },
    mixins: [myMixin],
    data() {
      let validatePass2 = (rule, value, callback) => {
        if (value !== this.addUserForm.password) {
          callback(new Error("两次密码不一致!"));
        } else {
          callback();
        }
      };
      let validatePass3 = (rule, value, callback) => {
        if (value !== this.changePasswordForm.password) {
          callback(new Error("两次密码不一致!"));
        } else {
          callback();
        }
      };
      return {


        form: {
          username: '',
          page: 1,
        },

        data: [],//数据
        selectedRowKeys: [],//选择的表格
        pagination: {
          showQuickJumper: true,//是否快速跳转某页
          showSizeChanger: true,//是否可以改变条数
          pageSizeOptions: ['10', '20', '50', '100', '200'],
          showTotal: (total, range) => `当前显示第${range[0]}-${range[1]}，总条数：${total} `
        },//分页器
        loading: false,//是否加载中
        btnLoading: false,
        columns,//列描述
        tableSize: 'default',//表格尺寸


        addConfirmLoading: false,//添加模态框
        addModalVisible: false,
        addUserForm: {
          username: '',
          password: '',
          password1: '',

        },
        addUserFormRules: {
          username: [
            { required: true, message: '请输入用户名', trigger: 'blur' },
          ],

          password: [
            { required: true, message: '请选择角色', trigger: 'blur' },
            { message: '密码长度为6-18', trigger: 'blur', min: 6, max: 18 },
          ],
          password1: [
            { validator: validatePass2, required: true, trigger: 'blur' },
          ],


        },

        changePasswordConfirmLoading: false,//修改密码模态框
        changePasswordModalVisible: false,
        changePasswordForm: {
          user_id: '',
          password: '',
          password1: '',

        },
        changePasswordFormRules: {//修改密码表单验证规则
          password: [
            { required: true, message: '请选择角色', trigger: 'blur' },
            { message: '密码长度为6-18', trigger: 'blur', min: 6, max: 18 },
          ],
          password1: [
            { validator: validatePass3, required: true, trigger: 'blur' },
          ],

        },
      }

    },
    created() {
      this.fetch();
    },
    mounted() {
    },
    methods: {

      reset(formName) {
        this.$refs[formName].resetFields();
        this.pagination.current = 1;
        this.fetch({ page: 1, page_size: this.pagination.pageSize });
      },
      onSelectChange(selectedRowKeys) {//选择表格
        this.selectedRowKeys = selectedRowKeys;
      },

      start() {//下载
        console.log("导出", this.selectedRowKeys)
        // ajax request after empty completing
        setTimeout(() => {
          this.selectedRowKeys = [];
        }, 1000);
      },

      handleTableChange(pagination, filters, sorter) {
        //pagination:分页信息，filters：筛选信息，sorter：排序信息
        this.pagination = { ...pagination };
        console.log(this.pagination, pagination)
        this.fetch({
          page_size: pagination.pageSize,
          page: pagination.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          ...filters,
        });
      },
      async fetch(params = {}) {
        this.loading = true;
        let data = await getUserList({ page_size: 10, ...params, ...this.form })
        this.loading = false;
        const pagination = { ...this.pagination };
        pagination.total = data.data.total;

        this.data = data.data.list;

        this.pagination = pagination;
        console.log(this.data, data, 'data数据')
      },
      //禁用账号
      async onDisableUser(data) {
        let resule = await deleteUser({ user_id: data.id });
        if (resule.code == 200) {
          this.$message.success(resule.msg);
          this.fetch()
        } else {
          this.$message.error(resule.msg);
        }
      },


      //修改密码
      async onChangePassword(data) {
        this.changePasswordForm.user_id = data.id
        this.changePasswordModalVisible = true
      },
      //保存密码
      async savePassword(data) {
        this.$refs.changePassword.validate(async valid => {
          if (valid) {
            this.changePasswordConfirmLoading = true;
            let resule = await updateMerchants(this.changePasswordForm);
            if (resule.code == 200) {
              console.log(resule)
              this.$message.success(resule.msg);
              this.changePasswordModalVisible = false;
              this.changePasswordConfirmLoading = false;
              this.$refs.changePassword.resetFields();
              this.fetch();
            } else {
              this.$message.error(resule.msg);
              this.changePasswordConfirmLoading = false;
            }

          } else {
            return false;
          }

        })
      },
      cancelChangePassword() {
        this.$refs.changePassword.resetFields();
        this.changePasswordModalVisible = false;
      },

      //添加用户数据
      onAddUser() {
        this.addModalVisible = true
      },
      add_saveUser() {
        this.$refs.addUser.validate(async valid => {
          if (valid) {
            this.addConfirmLoading = true;

            let resule = await addUser(this.addUserForm);
            if (resule.code == 200) {
              console.log(resule)
              this.$message.success(resule.msg);
              this.addModalVisible = false;
              this.addConfirmLoading = false;
              this.$refs.addUser.resetFields();
              this.fetch();
            } else {
              this.$message.error(resule.msg);
              this.addConfirmLoading = false;
            }

          } else {
            return false;
          }
        });


      },
      add_cancelUser() {
        this.$refs.addUser.resetFields();
        this.addModalVisible = false;
      },

    }
  };
</script>
<style lang="scss" scoped>
  @import "@/static/css/index.scss";

  .ant-table td {
    white-space: nowrap;
  }

  .ant-table td .greenBtn {
    background-color: #7ac141;
    border-color: #7ac141;
  }
</style>